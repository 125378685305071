export const locale = {
    lang: 'en',
    data: {
        MENU: {
            HOME: 'Home',
            SAMPLE: 'Sample',
            DASHBOARD: 'Dashboard',
            LANDING_PAGE: 'Landing Page',
            ADMINS: 'Admins',
            HEAD_COUNSELLORS: 'Head Counsellors',
            BUSINESS_HEAD_CENTERS: 'Business Head Centers',
            BUSINESS_CENTERS: 'Business Centers',
            COUNSELLORS: 'Counsello rs',
            UNIVERSITIES: 'Universities',
            UNIVERSITYSETTINGS: 'Settings',
            PROGRAMS: 'Universities',
            COURSES: 'Courses',
            BRANCHES: 'Branches',
            PCBMAPPING: 'PCB-Mapping',
            STAGES: 'Stages',
            REASONS: 'Reasons',
            SRMAPPING: 'SR-Mapping',
            STUDENTS: 'Students',
            MAILS: 'Mails',
            TEXT_MESSAGES: 'Text Messages',
            WHATSAPP: 'Whatsapp',
            COURSE_CURRICULUM: 'Course-Curriculum',
            B_ADMINS: 'business Admins',
        }
    }
};
