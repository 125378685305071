import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityService} from '../../shared/services/university.service';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessCenterService} from '../../shared/services/business-center.service';

@Component({
    selector: 'app-b-centers',
    templateUrl: './business-centers.component.html',
    styleUrls: ['./business-centers.component.scss']
})
export class BusinessCentersComponent implements OnInit {
    showSearch = false;
    assignedUniversities: any;
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    modalType: any;
    businessCenters: any = [];
    universities: any = [];
    selectedCounsellor: any;
    contentHeader: object;
    user: any;
    headCenterId: any;
    adminId: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private businessCenterService: BusinessCenterService,
                private businessHeadcenterService: BusinessHeadCenterService,
                private universityService: UniversityService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.headCenterId = this.activatedRoute.snapshot.params['id'];
        this.adminId = this.activatedRoute.snapshot.params['adminId'];
        if (this.adminId && this.user && this.user['user_type'] !== 'SUPER_ADMIN') {
            this.router.navigateByUrl('dashboard');
        }
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        if (this.headCenterId) {
            this.getBusinessHeadCenterDetail();
            //this.getBusinessHeadCentersCenters();
            //this.getUserUniversities();
        } else {
            this.get();
            //this.getUniversities();
        }
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadcenterService.detail((this.adminId) ? this.adminId : this.user['userId'], this.headCenterId).subscribe(data => {
            this.contentHeader = {
                headerTitle: (data && data['name']) ? data['name'] + '\'s : Business Centers' : 'Business Centers',
                actionButton: false,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Business Head Centers',
                            isLink: true,
                            link: '/business-head-centers'
                        },
                        {
                            name: 'Business Centers',
                            isLink: false
                        }
                    ]
                }
            };
            this.get();
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-business-center').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            if (this.headCenterId) {
                this.getBusinessHeadCenter({page: 1, total: null, perpage: 15, pages: null});
                this.get({page: 1, total: null, perpage: 15, pages: null});
            }/* else {
                this.get({page: 1, total: null, perpage: 15, pages: null});
            }*/
            this.toggleSidebar();
        }
    }

    assignUniversityModal(data, content, type) {
        this.selectedCounsellor = data;
        let assignedUniversities = [];
        if (this.selectedCounsellor && this.selectedCounsellor['counsellor_universities'] && this.selectedCounsellor['counsellor_universities'].length > 0) {
            this.selectedCounsellor['counsellor_universities'].forEach(counsellorUniversity => {
                assignedUniversities.push(counsellorUniversity['university']);
            });
        }
        this.assignedUniversities = assignedUniversities;
        this.modalType = type;
        this.modalService.open(content, {'size': 'md'});
    }

    updatePasswordModal(content, id, type) {
        this.id = id;
        this.modalType = type;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    getUserUniversities() {
        this.submitting = true;
        this.universities = [];
        this.universityService.getUserUniversity(this.headCenterId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                let universities = [];
                data['data'].forEach(data => {
                    if (data && data['university']) {
                        universities.push(data['university']);
                    }
                });
                this.universities = universities;
            } else {
                this.universities = [];
            }
        });
    }

    getUniversities() {
        this.submitting = true;
        this.universities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                let universities = [];
                data['data'].forEach(data => {
                    if (data && data['university']) {
                        universities.push(data['university']);
                    }
                });
                this.universities = universities;
            } else {
                this.universities = [];
            }
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.businessCenters = [];
        if (!this.adminId && !this.headCenterId) {
            this.businessCenterService.getBusinessCenters(this.user['userId'], params).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.businessCenters = data['data'];
                    this.pagination = {
                        page: data.current_page,
                        total: data.total,
                        perpage: data.per_page,
                        pages: data.last_page,
                    };
                } else {
                    this.businessCenters = [];
                }
                this.submitting = false;
            }, error => {
                this.submitting = false;
            });
        } else {
            this.businessCenterService.get((this.adminId) ? this.adminId : this.user['userId'], this.headCenterId, params).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.businessCenters = data['data'];
                    this.pagination = {
                        page: data.current_page,
                        total: data.total,
                        perpage: data.per_page,
                        pages: data.last_page,
                    };
                } else {
                    this.businessCenters = [];
                }
                this.submitting = false;
            }, error => {
                this.submitting = false;
            });
        }
    }

    getBusinessHeadCenter(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.businessCenters = [];
        /*this.businessHeadcenterService.getBusinessHeadCenters(this.headCenterId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.businessCenters = data['data'];
                if (this.businessCenters && this.businessCenters.length > 0) {
                    this.businessCenters.forEach(counsellor => {
                        counsellor['count'] = counsellor['counsellor_universities'].length;
                    });
                }
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.businessCenters = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });*/
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.businessCenterService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    /*assignUniversity() {
        if (this.assignedUniversities && this.assignedUniversities.length === 0) {
            this.alertService.showErrors('Please choose atleast one university');
        } else {
            let universityIds = [];
            this.assignedUniversities.forEach(university => {
                universityIds.push(university.id);
            });
            const params = {
                'university_ids': JSON.stringify(universityIds)
            };
            this.businessCenterService.counsellorUniversity(this.selectedCounsellor['counsellor'].id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                if (this.headCenterId) {
                    this.getBusinessHeadCenters();
                } else {
                    this.get();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }*/

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.businessCenterService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }
}
