import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityService} from '../../shared/services/university.service';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-b-headcenters',
    templateUrl: './business-head-centers.component.html',
    styleUrls: ['./business-head-centers.component.scss']
})
export class BusinessHeadCentersComponent implements OnInit {
    showSearch = false;
    assignedUniversities: any;
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    modalType: any;
    business_head_centers = [];
    universities: any = [];
    selectedHeadCounsellor: any;
    user: any;
    contentHeader: object;
    adminId: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private businessHeadcenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService,
                private universityService: UniversityService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute,
                private _location: Location) {
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        this.refresh();
    }

    refresh() {
        this.adminId = this.activatedRoute.snapshot.params['id'];
        if (this.user && this.user['user_type'] !== 'SUPER_ADMIN' && this.adminId) {
            this.router.navigateByUrl('dashboard');
        }
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });

        if (this.adminId) {
            this.getBusinessAdminDetail();
        } else {
            this.get();
        }
    }

    getBusinessAdminDetail() {
        this.businessAdminService.detail(this.adminId).subscribe(data => {
            this.contentHeader = {
                headerTitle: (data && data['name']) ? data['name'] + '\'s : Business Head Centers' : 'Business Head Centers',
                actionButton: false,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Business Admins',
                            isLink: true,
                            link: '/business-admins'
                        },
                        {
                            name: 'Business Head Centers',
                            isLink: false
                        }
                    ]
                }
            };
            this.get();
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-business-head-center').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    assignUniversityModal(data, content, type) {
        this.selectedHeadCounsellor = data;
        let assignedUniversities = [];
        if (this.selectedHeadCounsellor && this.selectedHeadCounsellor['head_counsellor_universities'] && this.selectedHeadCounsellor['head_counsellor_universities'].length > 0) {
            this.selectedHeadCounsellor['head_counsellor_universities'].forEach(headCounsellorUniversity => {
                assignedUniversities.push(headCounsellorUniversity['university']);
            });
        }
        this.assignedUniversities = assignedUniversities;
        this.modalType = type;
        this.modalService.open(content, {'size': 'md'});
    }

    updatePasswordModal(content, business_head_center, type) {
        this.id = business_head_center['id'];
        this.modalType = type;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.business_head_centers = [];
        let admin = StorageService.getItem('self');
        this.businessHeadcenterService.get((!this.adminId) ? admin['userId'] : this.adminId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.business_head_centers = data['data'];
            } else {
                this.business_head_centers = [];
            }
            this.pagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.businessHeadcenterService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.businessHeadcenterService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    centersPage(data: any) {
        if (!this.adminId) {
            this.router.navigateByUrl('business-head-center/' + data.id + '/business-centers');
        } else {
            this.router.navigateByUrl('business-admin/' + this.adminId + '/business-head-center/' + data.id + '/business-centers');
        }
    }


    /*allowUniqueLink(data, e) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'unique_link_status': e ? 'ACTIVE' : 'INACTIVE'
            };
            this.submitting = true;
            this.businessHeadcenterService.updateUniqueLinkStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }*/
}
