import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BusinessHeadCentersRoutingModule} from './business-head-centers-routing.module';
import {BusinessHeadCentersComponent} from './business-head-centers.component';
import {AddBusinessHeadCentersComponent} from './add-business-head-centers/add-business-head-centers.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {UiSwitchModule} from 'ngx-ui-switch';
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
    declarations: [BusinessHeadCentersComponent, AddBusinessHeadCentersComponent],
    imports: [
        CommonModule,
        BusinessHeadCentersRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        UiSwitchModule,
        NgSelectModule
    ],
    exports: [BusinessHeadCentersComponent, AddBusinessHeadCentersComponent]
})
export class BusinessHeadCentersModule {
}
