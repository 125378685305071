import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessHeadCentersComponent} from './business-head-centers.component';
import {AddBusinessHeadCentersComponent} from './add-business-head-centers/add-business-head-centers.component';
import {BusinessCentersComponent} from '../business-centers/business-centers.component';

const routes: Routes = [

    {
        path: 'business-head-centers',
        component: BusinessHeadCentersComponent,
        data: {animation: 'business-head-centers'}
    },
    {
        path: 'business-head-centers/add',
        component: AddBusinessHeadCentersComponent,
        data: {animation: 'business-head-centers/add'}
    },
    {
        path: 'business-head-center/:id/business-centers',
        component: BusinessCentersComponent,
        data: {animation: 'business-head-center/:id/business-centers'}
    },
    {
        path: 'business-head-center/:id',
        component: BusinessHeadCentersComponent,
        data: {animation: 'business-head-center/:id'}
    }
    /*{
      path: 'business-head-centers/:id/centers',
      component: CounsellorsComponent,
      data: {animation: 'business-head-centers/:id/centers'}
    }*/
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessHeadCentersRoutingModule {
}
