import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr'; // For auth after login toast
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
// @ts-ignore
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {coreConfig} from 'app/app-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {AdminsModule} from './main/admins/admins.module';
import {SharedModule} from './shared/shared.module';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {GlobalService, MomentService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {HeadCounsellorsModule} from './main/head-counsellors/head-counsellors.module';
import {UniversitiesModule} from './main/universities/universities.module';
import {ProgramsModule} from './main/programs/programs.module';
import {CoursesModule} from './main/courses/courses.module';
import {BranchesModule} from './main/branches/branches.module';
import {PcbMappingModule} from './main/pcb-mapping/pcb-mapping.module';
import {StagesModule} from './main/stages/stages.module';
import {ReasonsModule} from './main/reasons/reasons.module';
import {SrMappingModule} from './main/sr-mapping/sr-mapping.module';
import {StudentsModule} from './main/students/students.module';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {MailsModule} from './main/mails/mails.module';
import {TextMessagesModule} from './main/text-messages/text-messages.module';
import {WhatsAppsModule} from './main/whats-apps/whats-apps.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {UniversitySettingsModule} from './main/university-settings/university-settings.module';
import {CourseCurriculumModule} from './main/course-curriculum/course-curriculum.module';
import {MyProfileModule} from './main/my-profile/my-profile.module';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AdmissionModule} from './main/admission/admission.module';
import {CounsellorsModule} from './main/counsellors/counsellors.module';
import {BusinessAdminsModule} from './main/business-admins/business-admins.module';
import {BusinessHeadCentersModule} from './main/business-head-centers/business-head-centers.module';
import {BusinessCentersModule} from './main/business-centers/business-centers.module';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/auth/miscellaneous/error' // Error 404 - Page not found
    }
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot(),
        // NgBootstrap
        NgbModule,
        ToastrModule.forRoot(),
        // Core modules
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        SharedModule,
        // App modules
        LayoutModule,
        SampleModule,
        SnotifyModule,
        DashboardModule,
        AdminsModule,
        HeadCounsellorsModule,
        UniversitiesModule,
        UniversitySettingsModule,
        ProgramsModule,
        CoursesModule,
        BranchesModule,
        PcbMappingModule,
        StagesModule,
        ReasonsModule,
        SrMappingModule,
        AdminsModule,
        StudentsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MailsModule,
        TextMessagesModule,
        WhatsAppsModule,
        PerfectScrollbarModule,
        ImageCropperModule,
        CourseCurriculumModule,
        MyProfileModule,
        AdmissionModule,
        CounsellorsModule,
        BusinessAdminsModule,
        BusinessHeadCentersModule,
        BusinessCentersModule
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        AuthGuard,
        HtpInterceptor,
        GlobalService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        MomentService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
})
export class AppModule {
}
