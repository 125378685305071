import {Component, OnInit, ViewChild} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {StageService} from '../../shared/services/stage.service';
import {StudentService} from '../../shared/services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SrMappingService} from '../../shared/services/sr-mapping.service';
import {AlertService} from '../../shared/services/alert.service';
import {Helper} from '../../shared/constants/helper';
import {StudentStageStatusService} from '../../shared/services/student-stage-status.service';
import {StorageService} from '../../shared/services/storage.service';
import {CourseService} from '../../shared/services/course.service';
import {Router} from '@angular/router';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {AdminService} from '../../shared/services/admin.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {UniversityService} from '../../shared/services/university.service';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';
import {ProgramService} from '../../shared/services/program.service';
import {UniversitySettingService} from '../../shared/services/university-setting.service';
import {BusinessCenterService} from '../../shared/services/business-center.service';
import {BusinessHeadCenterService} from '../../shared/services/business-head-center.service';
import {BusinessAdminService} from '../../shared/services/business-admin.service';
import {BranchService} from '../../shared/services/branch.service';
import {CourseCurriculumService} from '../../shared/services/course-curriculum.service';
import {AppConstants} from '../../shared/constants/app-constant';

@Component({
    selector: 'app-students',
    templateUrl: './students.component.html',
    styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
    id: any;
    data: any;
    tabs: any = [];
    studentDetail: any;
    selectedStudentData: any;
    selectedEnrollmentData: any;
    updateStatusForm: FormGroup;
    updateEnrollmentForm: FormGroup;
    addQuickLeadForm: FormGroup;
    reRegisterForm: FormGroup;
    bulkUniversityForm: FormGroup;
    studentSessionForm: FormGroup;
    stages: any = [];
    statuses: any = AppConstants.statuses;
    reasons: any = [];
    submitted: any = false;
    submitting: any = false;
    quickLeadAdd: any = false;
    reRegisterStudent: any = false;
    reRegisterStudentSelect: any;
    bulkUploadAdd: any = false;
    courses = [];
    universities = [];
    user: any;
    counsellorDetail: any;
    assignStudentData: any = false;
    admins: any = [];
    headCounsellors: any = [];
    counsellors: any = [];
    selectAssignable: any;
    studentChecked: any = false;
    onFileChanged: any;
    bulkStudentData: any = [];
    showFetchingDataLoader: any = false;
    showUpdatingDataLoader: any = false;
    transactionData: any;
    studentPagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    openSearch: any = false;
    searchUniversities = [];
    searchAdmins = [];
    searchHeadCounsellors = [];
    searchCounsellors = [];
    searchForm: FormGroup;
    filterPrograms: any = [];
    filterCourses: any = [];
    filterBranches: any = [];
    @ViewChild('navFilled') navFilled;
    active: any;
    activeTab: any = 0;
    headCounsellorDetailAdmin: any;
    headCounsellorUniversities: any = [];
    counsellorUniversities: any = [];
    counsellorHeadCounsellor: any = [];
    counsellorAdmin: any = [];
    studentSessionYears: any = [];
    businessCenterDetail: any;
    businessHeadCenterDetail: any;
    businessAdminDetail: any;
    selectedSession: any;
    pcbmapping: any = [];
    programs = [];
    studentPrograms = [];
    branches = [];
    studentCourses: any = [];
    studentBranches: any = [];
    stageTabs: any = [];
    showStudentYear = false;
    studentYears: any = [];
    showReRegistration: any = false;
    reRegistrationSession: any = [];
    remarkExists: any = false;

    constructor(private _coreSidebarService: CoreSidebarService,
                private stageService: StageService,
                private studentService: StudentService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private srMappingService: SrMappingService,
                public studentStageStatusService: StudentStageStatusService,
                public courseService: CourseService,
                public counsellorService: CounsellorService,
                public alertService: AlertService,
                private router: Router,
                private adminService: AdminService,
                private headCounsellorService: HeadCounsellorService,
                private universityService: UniversityService,
                private programService: ProgramService,
                private branchService: BranchService,
                private pcbMappingService: PcbMappingService,
                private courseCurriculumService: CourseCurriculumService,
                private universitySettingService: UniversitySettingService,
                private businessCenterService: BusinessCenterService,
                private businessHeadCenterService: BusinessHeadCenterService,
                private businessAdminService: BusinessAdminService) {
        this.getStages();
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        /*if (this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR')) {
            this.tabs = [
                {
                    'id': 'All',
                    'name': 'All',
                    'data': []
                },
                {
                    'id': 'Unassigned',
                    'name': 'Unassigned',
                    'data': []
                }
            ];
        }*/
        this.refresh();
    }

    refresh() {
        this.studentSessionForm = this.fb.group({
            'university_id': [''],
            'student_session_year': ['']
        });
        this.updateStatusForm = this.fb.group({
            'status': ['', Validators.required],
            'remarks': ['']
        });
        this.updateEnrollmentForm = this.fb.group({
            'enrollment_id': ['', Validators.required]
        });
        this.addQuickLeadForm = this.fb.group({
            'session_id': ['', Validators.required],
            'stage_id': ['', Validators.required],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': [''],
            'student_year': ['']
        });
        this.reRegisterForm = this.fb.group({
            'student_year': ['', Validators.required],
            'session_id': ['', Validators.required]
        });
        /*this.getCourses();
        this.getBranch();*/
        this.bulkUniversityForm = this.fb.group({
            'university_id': ['', Validators.required]
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
            //'universityId': [''],
            'programId': [''],
            'courseId': [''],
            'branchId': [''],
            //'adminId': [''],
            //'headCounsellorId': [''],
            //'counsellorId': ['']
        });
        this.getCounsellorDetail();
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins();
            this.getUniversitiesForSearch();
            this.getAdminsForSearch();
            this.getUniversities();
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getUniversitiesForSearch();
            this.getHeadCounsellors();
            // this.getHeadCounsellorsForSearch();
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getHeadCounsellorDetails(this.user['userId']);
            this.getCounsellors();
        } else if (this.user && this.user['user_type'] === 'COUNSELLOR') {
            this.getCounsellorDetails(this.user['userId']);
        } else if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
            this.getBusinessAdminDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
            this.getBusinessHeadCenterDetail();
        } else if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
            this.getBusinessCenterDetail();
        } else if (this.user && this.user['user_type'] === 'UNIVERSITY') {
            this.getStudentSessionData(this.user['userId']);
        }
    }

    get(search?) {
        this.tabs = [
            /*{
                'id': 'All',
                'name': 'All',
                'data': []
            },
            {
                'id': 'Unassigned',
                'name': 'Unassigned',
                'data': []
            }*/
        ];
        this.stageService.get({'page': -1}).subscribe(data => {
            let tabs = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    if (data && (data.id === 5 || data.id === 10 || data.id === 11 || data.id === 12)) {
                        tabs.push({
                            'id': data.id,
                            'name': data.name,
                            'data': []
                        });
                    }
                });
                this.tabs = tabs;
                this.stageTabs = [];
                if (tabs && tabs.length > 0) {
                    let stageTabs = [];
                    tabs.forEach(tab => {
                        if (tab.id !== 10) {
                            stageTabs.push(tab);
                        }
                    });
                    this.stageTabs = stageTabs;
                }
            }

            this.getStudents(this.studentPagination, search);
            /*if (this.activeTab === 1) {
                this.getTabData({
                    'id': 'Unassigned',
                    'name': 'Unassigned',
                    'data': []
                }, 1);
            } else {
                this.getStudents(this.studentPagination, search);
            }*/
        });
    }

    getUniversities() {
        this.universities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data['data'] && data['data'].length > 0) {
                this.universities = data['data'];
                this.studentSessionForm.patchValue({
                    'university_id': this.universities[this.universities.length - 1].id
                });
                this.getStudentSessionData(this.universities[this.universities.length - 1].id);
            } else {
                this.universities = [];
            }
        });
    }

    getBusinessCenterDetail() {
        this.businessCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessCenterDetail = data;
            this.getStudentSession();
        });
    }

    getBusinessHeadCenterDetail() {
        this.businessHeadCenterService.detailById(this.user['userId']).subscribe(data => {
            this.businessHeadCenterDetail = data;
            this.getStudentSession();
        });
    }

    getBusinessAdminDetail() {
        this.businessAdminService.detailById(this.user['userId']).subscribe(data => {
            this.businessAdminDetail = data;
            this.getStudentSession();
        });
    }

    getAdmins(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
        });
    }

    getHeadCounsellorDetails(id) {
        this.headCounsellorService.detail(id).subscribe(data => {
            this.headCounsellorDetailAdmin = data['head_counsellor_detail']['admin'];
            this.headCounsellorUniversities = data['head_counsellor_universities'];
        });
    }

    getCounsellorDetails(id) {
        this.counsellorService.detail(id).subscribe(data => {
            this.counsellorUniversities = data['counsellor_universities'];
            this.counsellorHeadCounsellor = data['counsellor_detail']['head_counsellor']['user'];
            this.counsellorAdmin = data['counsellor_detail']['head_counsellor']['admin'];
        });
    }

    getAdminsForSearch(params: any = {}) {
        this.searchAdmins = [];
        this.adminService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchAdmins = data['data'];
            } else {
                this.searchAdmins = [];
            }
        });
    }

    getHeadCounsellorsForSearch(event) {
        if (event && event.target && event.target.value) {
            this.searchHeadCounsellors = [];
            this.adminService.getAdminsHeadCounsellors(event.target.value, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.searchHeadCounsellors = data['data'];
                } else {
                    this.searchHeadCounsellors = [];
                }
            }, error => {
                this.searchHeadCounsellors = [];
            });
        } else {
            this.searchHeadCounsellors = [];
        }
    }

    getCounsellorsForSearch(event) {
        this.searchCounsellors = [];
        this.headCounsellorService.getHeadCounsellorsCounsellors(event.target.value, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchCounsellors = data['data'];
            } else {
                this.searchCounsellors = [];
            }
        }, error => {
            this.searchCounsellors = [];
        });
    }

    getUniversitiesForSearch(params: any = {}) {
        this.searchUniversities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.searchUniversities = data['data'];
            }
            /*if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }*/
        });
    }

    getHeadCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.headCounsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.headCounsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.headCounsellors = [];
            }
        });
    }

    getCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.counsellors = [];
        this.counsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.counsellors = [];
            }
        });
    }

    getCounsellorDetail() {
        if (this.user && this.user['userId']) {
            this.counsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.counsellorDetail = data;
                    this.universities = (this.counsellorDetail && this.counsellorDetail['counsellor_universities'] && this.counsellorDetail['counsellor_universities'].length > 0) ? this.counsellorDetail['counsellor_universities'] : [];
                    if (this.universities && this.universities.length > 0) {
                        if (this.user && this.user['user_type'] === 'COUNSELLOR') {
                            this.getUniversityCourses(this.universities[0].university_id);
                        }
                    }
                }
            });
        }
    }

    getUniversityCourses(universityId) {
        if (universityId) {
            this.courseService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        } else {
            this.alertService.showErrors('Please choose university');
        }
    }

    getStages() {
        this.stages = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    checkForRemark(value) {
        if (value === 'IN_PROGRESS') {
            this.remarkExists = true;
            this.updateStatusForm.patchValue({
                'remarks': ''
            });
        } else {
            this.remarkExists = false;
        }
    }

    getStageWiseStudents(stageId, index, params: any = {}) {
        params.name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
        params.username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
        params.email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
        params.contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
        params.universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
        params.programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
        params.courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
        params.branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
        /*params.adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
        params.headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
        params.counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');*/
        params.session_id = this.selectedSession.id;
        if (!params.name && !params.username && !params.email && !params.contact && !params.universityId && !params.programId && !params.courseId && !params.branchId && !params.adminId && !params.headCounsellorId && !params.counsellorId) {
            params.page = (this.studentPagination.page) ? this.studentPagination.page : 1;
            params.total = (this.studentPagination.total) ? this.studentPagination.total : null;
            params.per_page = (this.studentPagination.perpage) ? this.studentPagination.perpage : 15;
            params.pages = (this.studentPagination.pages) ? this.studentPagination.pages : null;
            /*params.page = this.studentPagination.page;
            params.per_page = this.studentPagination.perpage;*/
        } else {
            params.page = -1;
        }
        /*params.page = this.studentPagination.page;
        params.per_page = this.studentPagination.perpage;*/
        this.studentService.getByStage(stageId, params).subscribe(data => {
            this.tabs[index].data = [];
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data['student_id']) ? data['student_id'] : '',
                        'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                        'enrollment_id': (data['enrollment_id'] && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                        'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].id) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].id) ? data['student']['course_details']['branch'].id : '',
                        'status': (data && data['status'] && data['status']) ? (data['status'] === 'IN_PROGRESS' ? 'IN PROGRESS' : data['status'].toLowerCase()) : '-',
                        'remarks': (data && data['remarks']) ? data['remarks'] : '',
                        'status_id': (data && data['stage'] && data['stage'].id) ? data['stage'].id : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student'] && data['student']['student_detail']) && data['student']['student_detail']['student_year'] ? data['student']['student_detail']['student_year'] : '',
                        'business_center': (data && data['business_center']) ? data['business_center'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['head_counsellor'] && data['head_counsellor']) ? data['head_counsellor'] : ''
                    });
                });
                //this.tabs[0].data = allData;
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                                        let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                                        let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                                        let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                                        let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                                        let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                                        let chargeableCourseFee = 0;
                                        let total = 0;
                                        if (tab && tab.id === 10) {
                                            chargeableCourseFee = re_registration_amount * ((all['business_center']['business_center_detail'].re_registration_percentage) / 100);
                                            total = prospectus_fee + re_registration_amount + chargeableCourseFee + service_charge + examination_fee;
                                        } else {
                                            chargeableCourseFee = course_fee * ((all['business_center']['business_center_detail'].admission_percentage) / 100);
                                            total = prospectus_fee + registration_amount + chargeableCourseFee + service_charge + examination_fee;
                                        }
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'enrollment_id': all.enrollment_id,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'program_id': all.program_id,
                                            'course_id': all.course_id,
                                            'branch_id': all.branch_id,
                                            'program': all.program,
                                            'course': all.course,
                                            'branch': all.branch,
                                            'status': all.status,
                                            'remarks': all.remarks,
                                            'status_id': all.status_id,
                                            'register_course_id': all.register_course_id,
                                            'student_year': all.student_year,
                                            'register_course': all.register_course,
                                            'business_center': all.business_center,
                                            'added_by': all.added_by,
                                            'pcb_mapping': all.pcb_mapping,
                                            'total': total,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor,
                                            'chargeableCourseFee': chargeableCourseFee
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
            }

            const name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
            const username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
            const email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
            const contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
            const universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
            const programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
            const courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
            const branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
            /*const adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
            const headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
            const counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');*/

            if (!name && !username && !email && !contact && !universityId && !programId && !courseId && !branchId) {
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.studentPagination = {
                    page: 1,
                    total: null,
                    perpage: null,
                    pages: null
                    /*page: -1,
                    total: undefined,
                    perpage: undefined,
                    pages: undefined,*/
                };
            }
            /*this.studentPagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };*/
        });
    }

    getStudents(params: any = {}, search?) {
        params.name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
        params.username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
        params.email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
        params.contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
        params.universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
        params.programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
        params.courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
        params.branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
        params.adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
        params.headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
        params.counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');
        params.session_id = (this.selectedSession.id) ? this.selectedSession.id : null;
        if (!params.name && !params.username && !params.email && !params.contact && !params.universityId && !params.programId && !params.courseId && !params.branchId && !params.adminId && !params.headCounsellorId && !params.counsellorId) {
            params.page = (this.studentPagination.page) ? this.studentPagination.page : 1;
            params.total = (this.studentPagination.total) ? this.studentPagination.total : null;
            params.per_page = (this.studentPagination.perpage) ? this.studentPagination.perpage : 15;
            params.pages = (this.studentPagination.pages) ? this.studentPagination.pages : null;
            /*params.page = this.studentPagination.page;
            params.per_page = this.studentPagination.perpage;*/
        } else {
            params.page = -1;
        }
        this.studentService.get(params).subscribe(data => {
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'] && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data['student_id']) ? data['student_id'] : '',
                        'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                        'enrollment_id': (data['enrollment_id'] && data['enrollment_id']) ? data['enrollment_id'] : '-',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                        'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                        'program_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].id) ? data['student']['course_details']['program'].id : '',
                        'course_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].id : '',
                        'branch_id': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].id : '',
                        'program': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['program'] && data['student']['course_details']['program'].name) ? data['student']['course_details']['program'].name : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status': (data && data['status'] && data['status']) ? (data['status'] === 'IN_PROGRESS' ? 'IN PROGRESS' : data['status'].toLowerCase()) : '-',
                        'remarks': (data && data['remarks']) ? data['remarks'] : '',
                        'status_id': (data && data['stage'] && data['stage'].id) ? data['stage'].id : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'student_year': (data && data['student_year']) ? data['student_year'] : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'transactions': (data && data['transactions']) ? (data && data['transactions']) : [],
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['head_counsellor'] && data['head_counsellor']) ? data['head_counsellor'] : '',
                        'business_center': (data && data['business_center'] && data['business_center']) ? data['business_center'] : ''
                    });
                });
                this.tabs[0].data = [];
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        let prospectus_fee = (all && all.pcb_mapping && all.pcb_mapping.prospectus_fee) ? parseInt(all.pcb_mapping.prospectus_fee) : 0;
                                        let registration_amount = (all && all.pcb_mapping && all.pcb_mapping.registration_amount) ? parseInt(all.pcb_mapping.registration_amount) : 0;
                                        let re_registration_amount = (all && all.pcb_mapping && all.pcb_mapping.re_registration_amount) ? parseInt(all.pcb_mapping.re_registration_amount) : 0;
                                        let course_fee = (all && all.pcb_mapping && all.pcb_mapping.course_fee) ? parseInt(all.pcb_mapping.course_fee) : 0;
                                        let service_charge = (all && all.pcb_mapping && all.pcb_mapping.service_charge) ? parseInt(all.pcb_mapping.service_charge) : 0;
                                        let examination_fee = (all && all.pcb_mapping && all.pcb_mapping.examination_fee) ? parseInt(all.pcb_mapping.examination_fee) : 0;
                                        let chargeableCourseFee = 0;
                                        let total = 0;
                                        if (tab && tab.id === 10) {
                                            chargeableCourseFee = re_registration_amount * ((all['business_center']['business_center_detail'].re_registration_percentage) / 100);
                                            total = prospectus_fee + re_registration_amount + chargeableCourseFee + service_charge + examination_fee;
                                        } else {
                                            chargeableCourseFee = course_fee * ((all['business_center']['business_center_detail'].admission_percentage) / 100);
                                            total = prospectus_fee + registration_amount + chargeableCourseFee + service_charge + examination_fee;
                                        }
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'enrollment_id': all.enrollment_id,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'program_id': all.program_id,
                                            'course_id': all.course_id,
                                            'branch_id': all.branch_id,
                                            'program': all.program,
                                            'course': all.course,
                                            'branch': all.branch,
                                            'status': all.status,
                                            'remarks': all.remarks,
                                            'status_id': all.status_id,
                                            'transactions': all.transaction,
                                            'register_course_id': all.register_course_id,
                                            'register_course': all.register_course,
                                            'student_year': all.student_year,
                                            'added_by': all.added_by,
                                            'pcb_mapping': all.pcb_mapping,
                                            'total': total,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor,
                                            'business_center': all.business_center,
                                            'chargeableCourseFee': chargeableCourseFee
                                        });
                                    }
                                });
                            }
                        }
                    });
                    this.active = search ? this.activeTab : 0;
                }
            }

            const name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
            const username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
            const email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
            const contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
            const universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
            const programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
            const courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
            const branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
            const adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
            const headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
            const counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');
            const businessCenterId = (this.searchForm.value && this.searchForm.value.businessCenterId ? this.searchForm.value.businessCenterId : '');

            if (!name && !username && !email && !contact && !universityId && !programId && !courseId && !branchId && !adminId && !headCounsellorId && !counsellorId && !businessCenterId) {
                this.studentPagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.studentPagination = {
                    page: 1,
                    total: null,
                    perpage: null,
                    pages: null
                    /*page: -1,
                    total: undefined,
                    perpage: undefined,
                    pages: undefined,*/
                };
            }
        }, error => {
            this.active = search ? this.activeTab : 0;
        });
    }

    getTabData(tab, index, params: any = {}) {
        this.activeTab = index;
        if (tab && tab.id !== 'All') {
            this.getStageWiseStudents(tab.id, index);
            /*if (tab && tab.id === 'Unassigned') {
                params.name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
                params.username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
                params.email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
                params.contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
                params.universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
                params.programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
                params.courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
                params.branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
                params.adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
                params.headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
                params.counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');
                if (!params.name && !params.username && !params.email && !params.contact && !params.universityId && !params.programId && !params.courseId && !params.branchId && !params.adminId && !params.headCounsellorId && !params.counsellorId) {
                    params.page = (this.studentPagination.page) ? this.studentPagination.page : 1;
                    params.total = (this.studentPagination.total) ? this.studentPagination.total : null;
                    params.per_page = (this.studentPagination.perpage) ? this.studentPagination.perpage : 15;
                    params.pages = (this.studentPagination.pages) ? this.studentPagination.pages : null;
                    /!*params.page = this.studentPagination.page;
                    params.per_page = this.studentPagination.perpage;*!/
                } else {
                    params.page = -1;
                }
                /!*params.page = this.studentPagination.page;
                params.per_page = this.studentPagination.perpage;*!/
                this.studentService.getUnAssignedStudents(params).subscribe(data => {
                    if (data && data['data'] && data['data'].length > 0) {
                        let unassignedData = [];
                        data['data'].forEach(data => {
                            if (data && (data['added_by'] === this.user['userId']) && this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR')) {
                                unassignedData.push({
                                    'id': (data['student_id']) ? data['student_id'] : '',
                                    'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                                    'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                                    'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                                    'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                                    'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                                    'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                                    'status': (data && data['stage'] && data['stage'].name) ? data['stage'].name : '',
                                    'status_id': (data && data['stage'] && data['stage'].id) ? data['stage'].id : '',
                                    'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                                    'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                                    'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                                    'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                                    'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                                    'university': (data && data['university']) ? data['university'] : '',
                                    'added_by': (data && data['added_by']) ? data['added_by'] : '',
                                    'pcb_mapping': (data && data['pcb_mapping']) ? data['pcb_mapping'] : '',
                                    'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                                    'head_counsellor': (data && data['head_counsellor'] && data['head_counsellor']) ? data['head_counsellor'] : ''
                                });
                            }
                        });
                        this.tabs[index].data = unassignedData;
                    } else {
                        this.tabs[index].data = [];
                    }

                    const name = (this.searchForm.value && this.searchForm.value.name ? this.searchForm.value.name : '');
                    const username = (this.searchForm.value && this.searchForm.value.username ? this.searchForm.value.username : '');
                    const email = (this.searchForm.value && this.searchForm.value.email ? this.searchForm.value.email : '');
                    const contact = (this.searchForm.value && this.searchForm.value.contact ? this.searchForm.value.contact : '');
                    const universityId = (this.searchForm.value && this.searchForm.value.universityId ? this.searchForm.value.universityId : '');
                    const programId = (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : '');
                    const courseId = (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '');
                    const branchId = (this.searchForm.value && this.searchForm.value.branchId ? this.searchForm.value.branchId : '');
                    const adminId = (this.searchForm.value && this.searchForm.value.adminId ? this.searchForm.value.adminId : '');
                    const headCounsellorId = (this.searchForm.value && this.searchForm.value.headCounsellorId ? this.searchForm.value.headCounsellorId : '');
                    const counsellorId = (this.searchForm.value && this.searchForm.value.counsellorId ? this.searchForm.value.counsellorId : '');

                    if (!name && !username && !email && !contact && !universityId && !programId && !courseId && !branchId && !adminId && !headCounsellorId && !counsellorId) {
                        this.studentPagination = {
                            page: data.current_page,
                            total: data.total,
                            perpage: data.per_page,
                            pages: data.last_page,
                        };
                    } else {
                        this.studentPagination = {
                            page: 1,
                            total: null,
                            perpage: null,
                            pages: null
                            /!*page: -1,
                            total: undefined,
                            perpage: undefined,
                            pages: undefined,*!/
                        };
                    }
                    /!*this.studentPagination = {
                        page: data.current_page,
                        total: data.total,
                        perpage: data.per_page,
                        pages: data.last_page,
                    };*!/
                });
            } else {
                this.getStageWiseStudents(tab.id, index);
            }*/
        } else {
            this.getStudents(this.studentPagination);
        }
    }

    getUniversityPrograms(event) {
        this.programService.getByAdmin(event.target.value, {'page': -1}).subscribe(data => {
            this.filterPrograms = data['data'];
        }, error => {
            this.filterPrograms = [];
        });
    }

    getProgramCourses(event) {
        this.courseService.getByAdmin(event.target.value, {'page': -1}).subscribe(data => {
            this.filterCourses = data['data'];
        }, error => {
            this.filterCourses = [];
        });
    }

    getCourseBranches(event) {
        if (!this.searchForm.value || !this.searchForm.value.universityId || this.searchForm.value.universityId === '') {
            this.alertService.showErrors('Please choose university');
        } else if (!this.searchForm.value || !this.searchForm.value.programId || this.searchForm.value.programId === '') {
            this.alertService.showErrors('Please choose program');
        } else if (!this.searchForm.value || !this.searchForm.value.courseId || this.searchForm.value.courseId === '') {
            this.alertService.showErrors('Please choose course');
        } else {
            let params = {
                'program_id': (this.searchForm.value && this.searchForm.value.programId ? this.searchForm.value.programId : ''),
                'course_id': (this.searchForm.value && this.searchForm.value.courseId ? this.searchForm.value.courseId : '')
            };
            this.pcbMappingService.getByAdmin(this.searchForm.value.universityId, params).subscribe(data => {
                this.filterBranches = data['data'];
            }, error => {
                this.filterBranches = [];
            });
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('detail-student').toggleOpen();
    }

    detail(data: any) {
        this.studentDetail = data;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getStudents({page: 1, total: null, perpage: 15, pages: null});
        }
        this.toggleSidebar();
    }

    changeStatusModal(content, data) {
        this.selectedStudentData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedEnrollmentData = false;
        if (data && data['status'] && data['status'] !== '') {
            if (data['status'] === 'IN PROGRESS') {
                this.remarkExists = true;
                this.updateStatusForm.patchValue({
                    'remarks': (data && data['remarks']) ? data['remarks'] : ''
                });
            }
            this.updateStatusForm.patchValue({
                'status': (data['status'] !== 'IN PROGRESS') ? data['status'].toUpperCase() : 'IN_PROGRESS'
            });
        } else {
            this.updateStatusForm.patchValue({
                'status': '',
                'remarks': ''
            });
        }
        this.modalService.open(content, {'size': 'sm'});
    }

    changeEnrollmentModal(content, data) {
        this.selectedEnrollmentData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.selectedStudentData = false;
        this.updateEnrollmentForm.patchValue({
            'enrollment_id': (data && data['enrollment_id'] && data['enrollment_id'] !== '-') ? data['enrollment_id'] : ''
        });
        console.log('this.selectedEnrollmentData', this.selectedEnrollmentData);
        this.modalService.open(content, {'size': 'sm'});
    }

    updateStatus() {
        this.submitted = true;
        if (!this.updateStatusForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'status': (this.updateStatusForm.value && this.updateStatusForm.value.status) ? this.updateStatusForm.value.status : '',
                'remarks': (this.updateStatusForm.value && this.updateStatusForm.value.remarks && this.updateStatusForm.value.status === 'IN_PROGRESS') ? this.updateStatusForm.value.remarks : '',
            };
            this.submitting = true;
            this.studentService.studentUpdateStatusByBusiness(this.selectedStudentData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateEnrollment() {
        this.submitted = true;
        if (!this.updateEnrollmentForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'enrollment_id': (this.updateEnrollmentForm.value && this.updateEnrollmentForm.value.enrollment_id) ? this.updateEnrollmentForm.value.enrollment_id : ''
            };
            this.submitting = true;
            this.studentService.studentUpdateEnrollmentByBusiness(this.selectedEnrollmentData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
                this.getStudents({page: 1, total: null, perpage: 15, pages: null});
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    openBulkUploadModal(content) {
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.assignStudentData = false;
        this.quickLeadAdd = false;
        this.reRegisterStudent = false;
        this.bulkUploadAdd = true;
        this.bulkStudentData = [];
        this.modalService.open(content, {'size': 'xl'});
    }

    openQuickLeadModal(content) {
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.assignStudentData = false;
        this.bulkUploadAdd = false;
        this.reRegisterStudent = false;
        this.quickLeadAdd = true;
        this.modalService.open(content);
    }

    openReRegister(content, data) {
        this.selectedStudentData = undefined;
        this.quickLeadAdd = false;
        this.bulkUploadAdd = false;
        this.assignStudentData = false;
        this.reRegisterStudent = true;
        this.reRegisterStudentSelect = data;
        this.getReRegisterPCBMapping(this.reRegisterStudentSelect['program_id'], this.reRegisterStudentSelect['course_id'], this.reRegisterStudentSelect['branch_id']);
        this.modalService.open(content);
    }

    openAssignmentModal(content) {
        this.selectedStudentData = undefined;
        this.reRegisterStudent = undefined;
        this.quickLeadAdd = false;
        this.bulkUploadAdd = false;
        this.reRegisterStudent = false;
        this.assignStudentData = true;
        this.modalService.open(content, {'size': 'xl'});
    }


    submitReRegister() {
        this.submitted = true;
        if (!this.reRegisterForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_year': (this.reRegisterForm.value && this.reRegisterForm.value.student_year) ? this.reRegisterForm.value.student_year : '',
                'session_id': (this.reRegisterForm.value && this.reRegisterForm.value.session_id) ? this.reRegisterForm.value.session_id : '',
                'student_id': (this.reRegisterStudentSelect && this.reRegisterStudentSelect && this.reRegisterStudentSelect['id']) ? this.reRegisterStudentSelect['id'] : ''
            };
            this.submitting = true;
            this.studentService.studentReRegisterBusiness(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.reRegisterForm.reset();
                    this.reRegisterForm.patchValue({
                        'student_year': '',
                        'session_id': '',
                        'student_id': ''
                    });
                    this.modalService.dismissAll('');
                    this.getStudents();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].student_year.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_year[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].session_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].session_id[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].student_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].student_id[0]);
                        return;
                    }
                }
            });
        }
    }

    submitQuickLead() {
        this.submitted = true;
        if (!this.addQuickLeadForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'session_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.session_id) ? this.addQuickLeadForm.value.session_id : '',
                'stage_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.stage_id) ? this.addQuickLeadForm.value.stage_id : '',
                'name': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.name) ? this.addQuickLeadForm.value.name : '',
                'email': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.email) ? this.addQuickLeadForm.value.email : '',
                'contact': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.contact) ? this.addQuickLeadForm.value.contact : '',
                'program_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.program_id) ? this.addQuickLeadForm.value.program_id : '',
                'course_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.course_id) ? this.addQuickLeadForm.value.course_id : '',
                'branch_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.branch_id) ? this.addQuickLeadForm.value.branch_id : '',
                'student_year': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.student_year) ? this.addQuickLeadForm.value.student_year : '',
                'type': 'ADDED_BY_CENTER'
                //'added_by': this.user['userId']
            };
            /*this.pcbMappingService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.addQuickLeadForm.reset();
                        this.addQuickLeadForm.patchValue({
                            'program_id': '',
                            'course_id': '',
                            'branch_id': '',

                        });
                    }
                },*/
            this.submitting = true;
            this.studentService.studentRegisterBusiness(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.addQuickLeadForm.reset();
                    this.addQuickLeadForm.patchValue({
                        'university_id': '',
                        'stage_id': '',
                        'name': '',
                        'email': '',
                        'contact': '',
                        'program_id': '',
                        'course_id': '',
                        'branch_id': '',
                        'student_year': ''
                    });
                    this.modalService.dismissAll('');
                    this.getStudents();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    submitBulkUpload() {
        if (this.bulkStudentData && this.bulkStudentData.length > 0) {
            let repeatedEmail = Helper.repeatedObjects(this.bulkStudentData, 'email');
            let repeatedContact = Helper.repeatedObjects(this.bulkStudentData, 'contact');

            this.bulkStudentData.forEach(data => {
                data['errors']['name_message'] = false;
                data['errors']['email_message'] = false;
                data['errors']['contact_message'] = false;
                data['errors']['course_message'] = false;
                data['errors']['email_duplicate_message'] = false;
                data['errors']['contact_duplicate_message'] = false;
            });

            this.bulkStudentData.forEach(data => {
                if (repeatedEmail && repeatedEmail.length > 0) {
                    repeatedEmail.forEach(dt => {
                        if (dt.email === data.email) {
                            data['errors']['email_duplicate_message'] = 'Duplicate email address';
                        }
                    });
                }

                if (repeatedContact && repeatedContact.length > 0) {
                    repeatedContact.forEach(dt => {
                        if (dt.contact === data.contact) {
                            data['errors']['contact_duplicate_message'] = 'Duplicate contact number';
                        }
                    });
                }

                if (data && data.name === '') {
                    data['errors']['name_message'] = 'Name is required';
                }

                if (data && data.email !== '') {
                    if (!Helper.validateEmail(data.email)) {
                        data['errors']['email_duplicate_message'] = false;
                        data['errors']['email_message'] = 'Invalid email Format';
                    }
                } else {
                    data['errors']['email_duplicate_message'] = false;
                    data['errors']['email_message'] = 'Email is required';
                }

                if (data && data.course_id === '') {
                    data['errors']['course_message'] = 'Course is required';
                }

                if (data && data.contact !== '') {
                    if (!Helper.validateContact(data.contact)) {
                        data['errors']['contact_duplicate_message'] = false;
                        data['errors']['contact_message'] = 'Invalid contact Format';
                    }
                } else {
                    data['errors']['contact_duplicate_message'] = false;
                    data['errors']['contact_message'] = 'Contact is required';
                }
            });

            let errorCount = 0;
            this.bulkStudentData.forEach(data => {
                if (data && data['errors'] && (data['errors']['name_message'] || data['errors']['email_message'] || data['errors']['contact_message'] || data['errors']['course_message'] || data['errors']['email_duplicate_message'] || data['errors']['contact_duplicate_message'])) {
                    errorCount++;
                }
            });

            if (!errorCount) {
                this.submitting = true;
                this.submitted = true;
                this.showUpdatingDataLoader = true;
                let universityId = (this.universities && this.universities.length === 1) ? this.universities[0]['university'].id : ((this.bulkUniversityForm && this.bulkUniversityForm.value) ? this.bulkUniversityForm.value.university_id : '');
                this.studentService.studentBulkRegister({
                    'university_id': universityId,
                    'type': 'ADDED_BY_COUNSELLOR',
                    'students': this.bulkStudentData
                }).subscribe(data => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                    this.bulkUniversityForm.reset();
                    this.bulkUniversityForm.patchValue({
                        'university_id': ''
                    });
                    this.getStudents();
                    this.modalService.dismissAll('');
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                });
            }
        } else {
            this.alertService.showErrors('No student data found');
        }
    }

    navigateToUrl(url) {
        this.router.navigateByUrl(url);
    }

    onAssignPageChange(page) {
        this.pagination.page = page;
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins(this.pagination);
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getHeadCounsellors(this.pagination);
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getCounsellors(this.pagination);
        }
    }

    chooseAssignable(data, type, index, status) {
        if (type === 'ADMIN') {
            if (this.admins && this.admins.length > 0) {
                this.admins.forEach(admin => {
                    admin['assignable'] = false;
                });
                this.admins[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        } else if (type === 'HEAD_COUNSELLOR') {
            if (this.headCounsellors && this.headCounsellors.length > 0) {
                this.headCounsellors.forEach(headCounsellor => {
                    headCounsellor['assignable'] = false;
                });
                this.headCounsellors[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        } else if (type === 'COUNSELLOR') {
            if (this.counsellors && this.counsellors.length > 0) {
                this.counsellors.forEach(counsellor => {
                    counsellor['assignable'] = false;
                });
                this.counsellors[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        }
    }

    checkStudent() {
        if (this.tabs && this.tabs.length > 0) {
            let checkedData = [];
            this.tabs.forEach(tab => {
                if (tab && tab['data'] && tab['data'].length > 0) {
                    tab['data'].forEach(data => {
                        if (data && data['isChecked']) {
                            checkedData.push(data);
                        }
                    });
                }
            });
            this.studentChecked = checkedData && checkedData.length > 0;
        }
    }

    assignToStudent() {
        if (this.tabs && this.tabs.length > 0) {
            let checkedData = [];
            this.tabs.forEach(tab => {
                if (tab && tab['data']) {
                    tab['data'].forEach(data => {
                        if (data && data['isChecked']) {
                            checkedData.push(data);
                        }
                    });
                }
            });
            let counsellorType = 'Admin';
            let selectedAssignableId = (this.selectAssignable) ? this.selectAssignable.id : '';
            if (this.user && this.user['user_type'] === 'ADMIN') {
                counsellorType = 'Head Counsellor';
                selectedAssignableId = (this.selectAssignable && this.selectAssignable['user']) ? this.selectAssignable['user'].id : '';
            } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
                counsellorType = 'Counsellor';
                selectedAssignableId = (this.selectAssignable && this.selectAssignable['counsellor']) ? this.selectAssignable['counsellor'].id : '';
            }
            if (this.selectAssignable) {
                let students = [];
                let studentUniversities = [];
                if (checkedData && checkedData.length > 0) {
                    checkedData.forEach(student => {
                        students.push(student.id);
                        studentUniversities.push(student['university'].id);
                    });
                    if (students && students.length > 0) {
                        const params = {
                            'assign_to': selectedAssignableId,
                            'students': (students && students.length > 0) ? JSON.stringify(students) : ''
                        };
                        if (this.user && this.user['user_type'] && this.user['user_type'] === 'ADMIN') {
                            let universityExists = false;
                            if (this.selectAssignable && this.selectAssignable['head_counsellor_universities'] && this.selectAssignable['head_counsellor_universities'].length > 0) {
                                let assignableUniversities = [];
                                this.selectAssignable['head_counsellor_universities'].forEach(head_counsellor_university => {
                                    if (head_counsellor_university && head_counsellor_university['university']) {
                                        assignableUniversities.push(head_counsellor_university['university'].id);
                                    }
                                });
                                if (studentUniversities && assignableUniversities && assignableUniversities.length > 0 && studentUniversities.length > 0) {
                                    studentUniversities = Helper.removeDuplicates(studentUniversities);
                                    // universityExists = Helper.checkEqualArray(assignableUniversities, studentUniversities);
                                    universityExists = studentUniversities.every(function (val) {
                                        return assignableUniversities.indexOf(val) >= 0;
                                    });
                                }
                            }
                            if (universityExists) {
                                this.studentService.assignStudent(params).subscribe(data => {
                                    if (data) {
                                        this.getStudents();
                                        this.getTabData({
                                            'id': 'Unassigned',
                                            'name': 'Unassigned',
                                            'data': []
                                        }, 1);
                                        this.modalService.dismissAll('');
                                    }
                                });
                            } else {
                                this.alertService.showErrors('Student\'s Univeristy is not assigned to Head Counsellor');
                            }
                        } else {
                            this.studentService.assignStudent(params).subscribe(data => {
                                if (data) {
                                    this.getStudents();
                                    this.getTabData({
                                        'id': 'Unassigned',
                                        'name': 'Unassigned',
                                        'data': []
                                    }, 1);
                                    this.modalService.dismissAll('');
                                }
                            });
                        }
                    } else {
                        this.alertService.showErrors('Please choose students to assign ' + counsellorType);
                        return;
                    }
                } else {
                    this.alertService.showErrors('Please choose students to assign ' + counsellorType);
                    return;
                }
            } else {
                this.alertService.showErrors('Please choose ' + counsellorType + ' to assign students');
                return;
            }
        }
    }

    onStudentPageChange(page) {
        this.pagination.page = page;
        this.getStudents();
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.studentService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'STUDENT_BULK_UPLOAD') {
                this.studentBulkUpload(data['data'].url);
            }
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    studentBulkUpload(url) {
        this.showFetchingDataLoader = true;
        this.studentService.studentBulkUpload({'url': url}).subscribe(data => {
            if (data) {
                this.bulkStudentData = data;
                this.showFetchingDataLoader = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            this.showFetchingDataLoader = false;
        });
    }

    changeTransactionModel(content, data) {
        this.transactionData = data;
        this.modalService.open(content, {'size': 'xl'});
    }

    removeStudent(index) {
        this.bulkStudentData.splice(index, 1);
    }

    newUpload() {
        this.bulkStudentData = [];
    }

    downloadSample() {
        window.open('assets/docs/students.xlsx', '_blank');
    }

    goToTransactionReceipt(transaction_no) {
        this.studentService.getStudentReceiptUrl(this.transactionData['personal_info'].student_id, {'transaction_no': transaction_no}).subscribe(data => {
            window.open(data['url'], '_blank');
        });
    }

    showSearch() {
        this.openSearch = !this.openSearch;
    }

    search() {
        this.get(true);
    }

    getStudentSession() {
        if (this.businessCenterDetail && this.businessCenterDetail['business_admin_detail'] && this.businessCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessHeadCenterDetail && this.businessHeadCenterDetail['business_admin_detail'] && this.businessHeadCenterDetail['business_admin_detail'].university_id) {
            this.getStudentSessionData(this.businessHeadCenterDetail['business_admin_detail'].university_id);
        } else if (this.businessAdminDetail && this.businessAdminDetail['university_id']) {
            this.getStudentSessionData(this.businessAdminDetail['university_id']);
        }
    }

    getStudentSessionData(universityId) {
        this.universitySettingService.getByAdmin(universityId, {}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentSessionYears = data['data'];
                if (this.studentSessionYears && this.studentSessionYears.length > 0) {
                    let sessionYear = {};
                    this.studentSessionYears.forEach(session => {
                        if (session && session['session_default'] === 'ACTIVE') {
                            sessionYear = session;
                        }
                    });
                    this.patchFormSession(universityId, sessionYear);
                }
            }
        });
    }

    patchFormSession(universityId, data) {
        this.get(data.id);
        this.selectedSession = data;
        this.studentSessionForm.patchValue({
            'student_session_year': (data && data.id) ? data.id : ''
        });
        this.addQuickLeadForm.patchValue({
            'session_id': (data && data.id) ? data.id : ''
        });
        this.getUniversityCourses(universityId);
        this.getProgram(universityId);
        this.getStudentPrograms(universityId);
        this.reRegistrationStatus();
    }

    reRegistrationStatus() {
        if (this.studentSessionYears && this.studentSessionYears.length > 0) {
            this.showReRegistration = !(this.studentSessionYears && this.studentSessionYears[0] && this.studentSessionYears[0]['session_default'] && this.studentSessionYears[0]['session_default'] === 'ACTIVE');
            if (this.showReRegistration) {
                let reRegistrationSession = [];
                this.studentSessionYears.forEach(session => {
                    if (session && session['re_registration_status'] === 'ACTIVE') {
                        reRegistrationSession.push(session);
                    }
                });
                this.reRegistrationSession = reRegistrationSession;
            }
        }
    }

    changeStudentUniversity(event) {
        if (event && event.target && event.target.value) {
            this.getStudentSessionData(event.target.value);
        }
    }

    changeStudentSession(event) {
        if (event && event.target && event.target.value && this.studentSessionYears && this.studentSessionYears.length > 0) {
            let selectedSession = {};
            this.studentSessionYears.forEach(session => {
                if (parseInt(event.target.value) === session.id) {
                    selectedSession = session;
                }
            });
            this.selectedSession = selectedSession;
            this.get();
        }
    }

    getCourses() {
        this.courses = [];
        this.courseService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
            } else {
                this.courses = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getProgram(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
            } else {
                this.programs = [];
            }
        });
    }

    getStudentPrograms(universityId) {
        this.programs = [];
        this.programService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentPrograms = data['data'];
            } else {
                this.studentPrograms = [];
            }
        });
    }

    getStudenProgramCourses(event) {
        this.studentCourses = [];
        this.studentBranches = [];
        this.pcbMappingService.getByLanding(event.target.value, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.studentCourses = Helper.uniqByKeepLast(data['data'], it => it.course_id);
            }
        });
    }

    getStudentCourseBranch(event) {
        if (event) {
            this.studentBranches = [];
            this.pcbMappingService.getByLanding(event.target.value, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    let studentBranches = [];
                    data['data'].forEach(data => {
                        if (data && data.branch_id) {
                            studentBranches.push(data);
                        }
                    });
                    this.studentBranches = Helper.uniqByKeepLast(studentBranches, it => it.branch_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select course again');
        }
    }

    getBranch() {
        this.branches = [];
        this.branchService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.branches = data['data'];
            } else {
                this.branches = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    /*getPCBMapping(universityId) {
        this.pcbMappingService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
                this.programs = data['data'];
                this.branches = data['data'];
            } else {
                this.courses = data[''];
                this.programs = data[''];
                this.branches = data[''];
            }
        });
    }*/

    getStudentYear(event) {
        if (event && event.target.value && parseInt(this.addQuickLeadForm.value['stage_id']) === 12) {
            this.showStudentYear = (this.addQuickLeadForm.value && this.addQuickLeadForm.value['stage_id'] && parseInt(this.addQuickLeadForm.value['stage_id']) === 12);
            if (this.showStudentYear) {
                this.getPCBMapping();
            }
        }
    }

    getPCBMapping() {
        let params = {
            'program_id': (this.addQuickLeadForm && this.addQuickLeadForm.value && this.addQuickLeadForm.value.program_id) ? this.addQuickLeadForm.value.program_id : '',
            'course_id': (this.addQuickLeadForm && this.addQuickLeadForm.value && this.addQuickLeadForm.value.course_id) ? this.addQuickLeadForm.value.course_id : '',
            'branch_id': (this.addQuickLeadForm && this.addQuickLeadForm.value && this.addQuickLeadForm.value.branch_id) ? this.addQuickLeadForm.value.branch_id : ''
        };
        this.pcbMappingService.detailByPCB(params).subscribe(data => {
            if (data && data.id) {
                this.getCourseCurriculum(data.id);
            }
        });
    }

    getReRegisterPCBMapping(programId, courseId, branchId) {
        let params = {
            'program_id': programId,
            'course_id': courseId,
            'branch_id': branchId
        };
        this.pcbMappingService.detailByPCB(params).subscribe(data => {
            if (data && data.id) {
                this.getCourseCurriculum(data.id);
            }
        });
    }

    getCourseCurriculum(pcbMappingId) {
        this.courseCurriculumService.detailByPCBId(pcbMappingId).subscribe(data => {
            if (data) {
                let durations = (data && data['duration']) ? data['duration'] : 0;
                this.studentYears = Helper.makeArrayFromNumber(durations);
            }
        });
    }
}
