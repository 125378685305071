import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessCentersComponent} from './business-centers.component';

const routes: Routes = [
    {
        path: 'business-centers',
        component: BusinessCentersComponent,
        data: {animation: 'business-centers'}
    },
    {
        path: 'business-admin/:adminId/business-head-center/:id/business-centers',
        component: BusinessCentersComponent,
        data: {animation: 'business-admin/:adminId/business-head-center/:id/business-centers'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessCentersRoutingModule {
}
